import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = React.lazy(() => import('./App'));
const AuthArticle = React.lazy(() => import('./Page/CRM/AuthArticle'));
const AddArticle = React.lazy(() => import('./Page/CRM/AddArticle'));
const EditArticle = React.lazy(() => import('./Page/CRM/EditArticle'));
const AuthProtfolio = React.lazy(() => import('./Page/CRM/AuthProtfolio'));
const Login = React.lazy(() => import('./Page/Login'));
const Home = React.lazy(() => import('./Page/CRM/home'));
const About = React.lazy(() => import('./Page/About'));
const Portfolio = React.lazy(() => import('./Page/Portfolio'));
const BlogDetail = React.lazy(() => import('./Page/BlogDetail'));
const BlogTag = React.lazy(() => import('./Page/BlogTag'));
const PortfolioDetail = React.lazy(() => import('./Page/PortfolioDetail'));
const EditPortfolio = React.lazy(() => import('./Page/CRM/EditPortfolio'));
const AddProtfolio = React.lazy(() => import('./Page/CRM/AddProtfolio'));
const PortfolioTag = React.lazy(() => import('./Page/PortfolioTag'));
const BlogSearch = React.lazy(() => import('./Page/BlogSearch'));

 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <Router basename="/">
    <Routes>
      <Route exact path='/' element={ <React.Suspense fallback={<>...</>}> <App/> </React.Suspense> } />
      <Route exact path='/index' element={ <React.Suspense fallback={<>...</>}> <App/> </React.Suspense> } />
      <Route exact path='/blog' element={ <React.Suspense fallback={<>...</>}> <App/> </React.Suspense> } />
      <Route exact path='/blog/:id' element={ <React.Suspense fallback={<>...</>}> <BlogDetail/> </React.Suspense> } />
      <Route exact path='/blog/tag/:id' element={ <React.Suspense fallback={<>...</>}> <BlogTag/> </React.Suspense> } />
      <Route exact path='/portfolio' element={ <React.Suspense fallback={<>...</>}> <Portfolio/> </React.Suspense> } />
      <Route exact path='/portfolio/:id' element={ <React.Suspense fallback={<>...</>}> <PortfolioDetail/> </React.Suspense> } />
      <Route exact path='/portfolio/tag/:id' element={ <React.Suspense fallback={<>...</>}> <PortfolioTag/> </React.Suspense> } />
      <Route exact path='/about' element={ <React.Suspense fallback={<>...</>}> <About/> </React.Suspense> } />
      <Route exact path='/search/:str' element={ <React.Suspense fallback={<>...</>}> <BlogSearch/> </React.Suspense> } />


       
      <Route exact path='/spprivater/login' element={ <React.Suspense fallback={<>...</>}> <Login/> </React.Suspense> } />
      <Route exact path='/spprivater/home' element={ <React.Suspense fallback={<>...</>}> <Home/> </React.Suspense> } />
      <Route exact path='/spprivater/autharticle' element={ <React.Suspense fallback={<>...</>}> <AuthArticle/> </React.Suspense> } />
      <Route exact path='/spprivater/addarticle' element={ <React.Suspense fallback={<>...</>}> <AddArticle/> </React.Suspense> } />
      <Route exact path='/spprivater/editarticle/:id' element={ <React.Suspense fallback={<>...</>}> <EditArticle/> </React.Suspense> } />
      <Route exact path='/spprivater/authprotfolio' element={ <React.Suspense fallback={<>...</>}> <AuthProtfolio/> </React.Suspense> } />
      <Route exact path='/spprivater/addportfolio' element={ <React.Suspense fallback={<>...</>}> <AddProtfolio/> </React.Suspense> } />
      <Route exact path='/spprivater/editportfolio/:id' element={ <React.Suspense fallback={<>...</>}> <EditPortfolio/> </React.Suspense> } />
     

    </Routes>
  </Router>
 
);






// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
